const applianceTypes = [
  'Oven',
  'Cooker',
  'Dishwasher',
  'Washing Machine',
  'Dryer',
  'Microwave',
  'Induction Hob',
  'Hob',
  'Cooker Hood'
];

module.exports = applianceTypes;
