export const applianceTypes = [
  "Washing Machine",
  "Dryer",
  "Dishwasher",
  "Refrigerator",
  "Freezer",
  "Oven",
  "Cooktop",
  "Microwave",
  "OTHER"
];

export const applianceBrands = [
  "Beko",
  "Bosch",
  "Electrolux",
  "Hotpoint",
  "Indesit",
  "LG",
  "Miele",
  "Samsung",
  "Siemens",
  "Whirlpool",
  "OTHER"
];

export const pricing = {
  basePrice: 80, // Default base price
  urgentFee: 50,
  serviceTypeFees: {
    repair: 0,
    maintenance: 20,
    installation: 40
  },
  appliancePricing: {
    "Washing Machine": 80,
    "Dryer": 80,
    "Dishwasher": 80,
    "Refrigerator": 85,
    "Freezer": 85,
    "Oven": 85,
    "Cooktop": 80,
    "Microwave": 75,
    "OTHER": 90
  },
  brandModifiers: {
    "Miele": 27.95,
    "Bosch": 15,
    "Siemens": 15,
    "Samsung": 10,
    "LG": 10,
    "OTHER": 0
  }
};
